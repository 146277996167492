<template>
  <div class="aboutus">
    <div class="contanier">
      <div class="contant">
        <!-- <h3>{{ article1.name }}</h3> -->
        <div v-html="article1.content"></div>
        <!-- <el-image
          style="width: 790px; height: 435px;"
          :src="require('@/util/images/index/pic_about.png')"
          fit="contain"
        ></el-image> -->
      </div>
      <el-collapse accordion v-model="activeNames" @change="handleChange">
        <el-collapse-item
          :name="index"
          v-for="(item, index) in infolist"
          :key="index"
        >
          <template slot="title">
            <el-image
              v-if="activeNames == index"
              style="width: 20px; height: 20px;margin-right:20px"
              :src="require('@/util/images/index/icon_bluejt_down.png')"
              fit="contain"
            ></el-image>
            <el-image
              v-if="activeNames != index"
              style="width: 20px; height: 20px;margin-right:20px "
              :src="require('@/util/images/index/icon_bluejt_right.png')"
              fit="contain"
            ></el-image>
            {{ item.name }}
          </template>
          <div v-html="item.content"></div>
        </el-collapse-item>
      </el-collapse>
      <h4>在我们的常见问题解答和知识库页面上阅读更多信息。</h4>
    </div>
  </div>
</template>

<script>
import { articleGuanyu } from "../../api/userapi";
export default {
  name: "aboutus",

  components: {},

  data() {
    return {
      article1: "",
      activeNames: 0,
      infolist: []
    };
  },

  mounted() {
    this.getinfo();
  },

  methods: {
    getinfo() {
      articleGuanyu().then(res => {
        this.infolist = res.article2;
        this.article1 = res.article1;
      });
    },
    handleChange(val) {}
  }
};
</script>

<style lang="less" scoped>
h4 {
  text-align: center;
  font-size: 14px;
  color: #000000;
  margin: 30px 0 100px 0;
}
/deep/.el-icon-arrow-right:before {
  content: "";
}
/deep/.el-collapse-item__wrap {
  background: #ffffff;
  border: 1px solid #d9dde1;
  padding: 20px;
}
/deep/.el-collapse-item__header {
  height: 60px;
  background: #f5f7f9;
  border: 1px solid #d9dde1;
  padding: 0 20px;
}
.contant {
  padding: 120px 0 50px 0;
  width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  h3 {
    text-align: center;
    font-size: 30px;
    color: #000000;
    line-height: 40px;
    margin-bottom: 10px;
  }
  p {
    text-align: center;
    font-size: 18px;
    color: #000000;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .con {
    margin-bottom: 100px;
  }
}
</style>
